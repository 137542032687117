import { IRegion } from '@/finsight/domain/vo/Region';

export const USOA_REGION_ID = '77fdfc56-826b-4cab-976c-175acb54e97a';
export const APAC_REGION_ID = 'e6bcb256-4616-4cf6-b6ef-b45ffd10849a';
export const EMEA_REGION_ID = '5e1f0a58-0754-42fa-b86f-bfdf0b1925e8';
export const LATM_REGION_ID = 'bb182bf1-ce66-4d5d-b249-f4e9e81be13d';

export enum RegionAbbreviations {
  USOA = 'USOA',
  APAC = 'APAC',
  EMEA = 'EMEA',
  LATM = 'LATM',
}

export const RegionAbbreviationById = {
  [USOA_REGION_ID]: RegionAbbreviations.USOA,
  [APAC_REGION_ID]: RegionAbbreviations.APAC,
  [EMEA_REGION_ID]: RegionAbbreviations.EMEA,
  [LATM_REGION_ID]: RegionAbbreviations.LATM,
};

class Region {
  static getDefault(): string {
    return RegionAbbreviations.USOA;
  }

  static isUsoaRegionId(regionId: string): boolean {
    return regionId === USOA_REGION_ID;
  }

  static isUsoaRegionOnly(regionIds: string[]): boolean {
    return regionIds.length === 1 && regionIds.includes(USOA_REGION_ID);
  }

  static getIDsByAbbreviations(regions: IRegion[], abbreviations: string): string[] {
    return regions
      .filter((region) => abbreviations.includes(region.abbreviation))
      .map((result) => result.id);
  }
}

export default Region;
