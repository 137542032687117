import { EXCLUDED_SECTOR_IDS } from '@/finsight/application/actions/deals/constants';
import * as FILTER_TYPES from '@/finsight/application/actions/pageFilters/constants';
import { IPrimaryIssuer } from '@/finsight/domain/vo/IDeal';

export interface IDealNameParams {
  primaryIssuer: Partial<IPrimaryIssuer>,
  seriesName: string,
}

export const formatDealName = (deal: IDealNameParams): string => {
  if (!Object.keys(deal).length) {
    return '';
  }

  const issuer = deal.primaryIssuer;
  const parts = [(issuer.name || '')];

  if (issuer.issuerTicker) {
    parts.push(`(${ issuer.issuerTicker })`);
  }

  parts.push(deal.seriesName || '');
  return parts.join(' ');
};

export const getIsIssuer = (includedCompanyRole: string): boolean => (includedCompanyRole === FILTER_TYPES.ISSUER);

export const getIsParent = (includedCompanyRole: string): boolean => (includedCompanyRole === FILTER_TYPES.PARENT);

export const prepareCompanyPayloadFilters = (pageFilters, payload) => {
  if (getIsParent(pageFilters.includedCompanyRole) || getIsIssuer(pageFilters.includedCompanyRole)) {
    return {
      ...payload.filters,
      includedCompanyId: pageFilters.targetViewIds,
      includedCompanyRole: pageFilters.includedCompanyRole,
    };
  }

  // For Scorecards - company role is Underwriter/Rating agency
  return {
    ...payload.filters,
    hasEitherPricingOrAnnouncedDate: false,
    dateFrom: pageFilters.dateFrom || null,
    dateTo: pageFilters.dateTo || null,
    includedCompanyId: pageFilters.targetViewIds,
    includedCompanyRole: pageFilters.includedCompanyRole,
    includedSectorId: pageFilters.sector?.sectorId || null,
    includedSubsectorId: pageFilters.sector?.subsectorId || null,
    includedIndustryId: pageFilters.industry?.industryId || null,
    includedSubindustryId: pageFilters.industry?.subindustryId || null,
  };
};

export const preparePayload = (pageFilters, page = 1) => {
  let payload = {
    page,
    filters: {
      hasEitherPricingOrAnnouncedDate: true,
      includedDealViewIds: null,
      includedProductIds: null,
      includedRegionIds: null,
      excludedSectorIds: null,
      includedSubsectorId: null,
      includedSectorId: null,
      includedIndustryId: null,
      includedSubindustryId: null,
      includedStatusGroups: [],
    },
    filterType: pageFilters.isOverview
      ? FILTER_TYPES.DEALSTREAM
      : pageFilters.getDealsBy,
  };

  // include products id's
  if (pageFilters.productIds && pageFilters.productIds.length) {
    payload.filters.includedProductIds = pageFilters.productIds;
  }

  // include regions id's
  if (pageFilters.regionIds && pageFilters.regionIds.length) {
    payload.filters.includedRegionIds = pageFilters.regionIds;
  }

  if (pageFilters.isOverview) {
    // Add excluded sector ids to request if we're filtering by sector and currently on Market Overview (consumer) page
    if (pageFilters.getDealsBy === FILTER_TYPES.SECTOR && pageFilters.isConsumerOverview) {
      payload.filters.excludedSectorIds = EXCLUDED_SECTOR_IDS;
    }
  } else {
    switch (pageFilters.getDealsBy) {
      case FILTER_TYPES.SECTOR:
        payload.filters.includedSectorId = pageFilters.sector.sectorId;
        if (pageFilters.sector.subsectorId) {
          payload.filters.includedSubsectorId = pageFilters.sector.subsectorId;
        }
        break;

      case FILTER_TYPES.INDUSTRY:
        payload.filters.includedIndustryId = pageFilters.industry.industryId;
        if (pageFilters.industry.subindustryId) {
          payload.filters.includedSubindustryId = pageFilters.industry.subindustryId;
        }
        break;
      case FILTER_TYPES.DEAL:
        payload.filters.includedDealViewIds = pageFilters.targetViewIds;
        payload.filters.hasEitherPricingOrAnnouncedDate = false;
        break;

      case FILTER_TYPES.COMPANY:
        payload.filters = prepareCompanyPayloadFilters(pageFilters, payload);
        break;
      default:
        break;
    }
  }

  return payload;
};
