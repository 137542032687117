import React, { createContext, useState, useContext, useEffect } from 'react';
import { IProduct } from '@/finsight/domain/vo/Product';
import { IRegion } from '@/finsight/domain/vo/Region';
import { SEPARATOR } from '@/finsight/ui/common/components/header/Header/constants';
import { useDictionariesContext } from '@/finsight/application/Dictionaries/DictionariesContext';
import { IHeader } from '@/finsight/domain/vo/IHeader';

interface IActiveProductRegionState {
  activeProduct: IProduct | {},
  activeRegion: IRegion | {},
  activeMapping: IHeader,
  isInitialized: boolean,
}

interface IActiveProductRegionReturn extends IActiveProductRegionState {
  setActiveProduct: (product: IProduct) => void,
  setActiveRegion: (region: IRegion) => void,
}

const useHeaderDataActiveMenu = (): IActiveProductRegionReturn => {
  const {
    mapping,
    defaultActiveMapping,
    defaultActiveRegion,
    defaultActiveProduct,
    isHeaderInitialized,
  } = useDictionariesContext();

  const [activeState, setActiveState] = useState<IActiveProductRegionState>({
    activeProduct: defaultActiveProduct,
    activeRegion: defaultActiveRegion,
    activeMapping: defaultActiveMapping,
    isInitialized: isHeaderInitialized,
  });
  useEffect(() => {
    if (isHeaderInitialized && !activeState.isInitialized) {
      setActiveState({
        activeProduct: defaultActiveProduct,
        activeRegion: defaultActiveRegion,
        activeMapping: defaultActiveMapping,
        isInitialized: isHeaderInitialized,
      });
    }
  }, [isHeaderInitialized, activeState.isInitialized]);

  const setActiveProduct = (product) => {
    setActiveState((prevState) => ({
      ...prevState,
      activeProduct: product,
      /* @ts-ignore */
      activeMapping: mapping[product.id + SEPARATOR + prevState.activeRegion.id],
    }));
  };

  const setActiveRegion = (region) => {
    setActiveState((prevState) => ({
      ...prevState,
      activeRegion: region,
      /* @ts-ignore */
      activeMapping: mapping[prevState.activeProduct.id + SEPARATOR + region.id],
    }));
  };
  return {
    ...activeState,
    setActiveProduct,
    setActiveRegion,
  };
};

export const HeaderDataActiveMenuContext = createContext(null);

export function useHeaderDataActiveMenuContext() {
  const context = useContext(HeaderDataActiveMenuContext);
  if (!context) {
    throw new Error('useHeaderMenuContext must be used within the HeaderMenuContext');
  }
  return context;
}

const HeaderDataActiveMenuContextProvider = ({ children }) => (
  <HeaderDataActiveMenuContext.Provider value={ useHeaderDataActiveMenu() }>
    { children }
  </HeaderDataActiveMenuContext.Provider>
);

export default HeaderDataActiveMenuContextProvider;
