import URL from '@/finsight/Router/urlConstants';
import { IProduct } from '@/finsight/domain/vo/Product';

export const ABS_PRODUCT_ID = 'df146e90-5edc-4182-9328-98cee1bd403b';
export const HYC_PRODUCT_ID = 'd74c0c41-aa65-4870-92c4-d9e6eb377802';
export const IGC_PRODUCT_ID = '837f88ba-b7cc-4f94-ad79-898d2d28148f';
export const LM_PRODUCT_ID = 'e2e0fb50-4a15-4503-853a-4bcbf94361d7';

export enum ProductAbbreviations {
  ABS = 'ABS',
  HYC = 'HYC',
  IGC = 'IGC',
  LM = 'LM',
}

export const ProductAbbreviationById = {
  [ABS_PRODUCT_ID]: ProductAbbreviations.ABS,
  [HYC_PRODUCT_ID]: ProductAbbreviations.HYC,
  [IGC_PRODUCT_ID]: ProductAbbreviations.IGC,
};

export const ProductIdByAbbreviation = {
  [ProductAbbreviations.ABS]: ABS_PRODUCT_ID,
  [ProductAbbreviations.HYC]: HYC_PRODUCT_ID,
  [ProductAbbreviations.IGC]: IGC_PRODUCT_ID,
  [ProductAbbreviations.LM]: LM_PRODUCT_ID,
};

class Product {
  static isAbsProductId(productId: string): boolean {
    return productId === ABS_PRODUCT_ID;
  }

  static isLMProductId(productId: string): boolean {
    return productId === LM_PRODUCT_ID;
  }

  static isAbsProductOnly(productIds: string[]): boolean {
    return productIds.length === 1 && productIds.includes(ABS_PRODUCT_ID);
  }

  static getIDsByAbbreviations(products: IProduct[], abbreviations: string): string[] {
    return products
      .filter((product) => abbreviations.includes(product.abbreviation))
      .map((result) => result.id);
  }

  static getDefault(): string {
    return ProductAbbreviations.ABS;
  }

  static getDefaultProductByPath(pathname: string): string {
    if (pathname === URL.MARKET_OVERVIEW_HYC) {
      return ProductAbbreviations.HYC;
    }
    if (pathname === URL.MARKET_OVERVIEW_IGC) {
      return ProductAbbreviations.IGC;
    }
    return ProductAbbreviations.ABS;
  }
}

export default Product;
