import config from '@/Framework/config';
import IconAccount from '@dealroadshow/uikit/core/components/Icon/IconAccount';
import IconBank from '@dealroadshow/uikit/core/components/Icon/IconBank';
import IconBondManager from '@dealroadshow/uikit/core/components/Icon/IconBondManager';
import IconCalendar from '@dealroadshow/uikit/core/components/Icon/IconCalendar';
import IconCapedge from '@dealroadshow/uikit/core/components/Icon/IconCapedge';
import IconCard from '@dealroadshow/uikit/core/components/Icon/IconCard';
import IconCrypto from '@dealroadshow/uikit/core/components/Icon/IconCrypto';
import IconDescription from '@dealroadshow/uikit/core/components/Icon/IconDescription';
import IconDocument from '@dealroadshow/uikit/core/components/Icon/IconDocument';
import IconEarningsCalendar from '@dealroadshow/uikit/core/components/Icon/IconEarningsCalendar';
import IconEnumeratedList from '@dealroadshow/uikit/core/components/Icon/IconEnumeratedList';
import IconFilters from '@dealroadshow/uikit/core/components/Icon/IconFilters';
import IconFolderOpen from '@dealroadshow/uikit/core/components/Icon/IconFolderOpen';
import IconInvestorSet from '@dealroadshow/uikit/core/components/Icon/IconInvestorSet';
import IconLayersGroup from '@dealroadshow/uikit/core/components/Icon/IconLayersGroup';
import IconMappings from '@dealroadshow/uikit/core/components/Icon/IconMappings';
import IconPlanet from '@dealroadshow/uikit/core/components/Icon/IconPlanet';
import IconReddit from '@dealroadshow/uikit/core/components/Icon/IconReddit';
import IconResearchDocument from '@dealroadshow/uikit/core/components/Icon/IconResearchDocument';
import IconTrace from '@dealroadshow/uikit/core/components/Icon/IconTrace';
import IconTable from '@dealroadshow/uikit/core/components/Icon/IconTable';
import IconUnarchive from '@dealroadshow/uikit/core/components/Icon/IconUnarchive';
import IconUserAnalytics from '@dealroadshow/uikit/core/components/Icon/IconUserAnalytics';

export const MARKET_OVERVIEW_ALL = 'fb81b2c8-040f-4119-a8a9-c5856dfc74fe';
export const MARKET_OVERVIEW_IGC = '5dc775f7-2d18-4771-b584-5b6102cd4cc4';
export const MARKET_OVERVIEW_HYC = '131e4113-be9b-43ab-baf8-3951807fbdb0';
export const MARKET_OVERVIEW_CONSUMER = '2f8f92a4-75d7-40ed-86c4-eaf330beafa2';
export const BOND_SCREENER = 'a4e55b04-110a-43a1-9fec-f70ab4903a7f';
export const CORPORATE_BOND_SCREENER = '30fb6811-e9e5-4ec8-85df-b41eef13cbc8';
export const CREDIT_FLOW_ARTICLES = '49fd7eaa-967d-4b8e-b502-a119446ff2c0';
export const BWICS = 'b1acab64-8462-4693-bc61-2f2966c568c6';
export const TRACE = '0f8d4388-be36-4c67-a835-b93495502129';
export const LEAGUE_TABLE = 'd1a2d5ca-8d16-44a6-a1d0-a38396cdeb77';
export const LOAN_LEVEL_DATA = '6da9bda0-e627-4611-a071-d4bf034c4d83';
export const NEW_ISSUER_PIPELINE = '2fd26681-f4f5-4eb6-aa95-4a780fe1b353';
export const OPEN_MARKET = '9ff9a746-7d44-4454-8024-7594ec6ef9af';
export const PORTFOLIO_MONITOR = 'dbd410e7-911f-4d67-b0b4-050f4a36bca4';
export const RESEARCH = '74ba724f-75ac-4ae5-a66c-ee61a82afbb2';
export const NEW_ISSUE_STATS = 'f3aa5ab3-7f2b-4302-b719-3a299eeb8bb9';
export const INVESTOR_SET = '6f8fc265-8ffe-4ba3-ad3d-6b163ef56948';
export const CAPEDGE_HOME = '1f8fc265-8ffe-4ba3-ad3d-6b163ef56948';
export const CALENDAR = '2f8fc265-8ffe-4ba3-ad3d-6b163ef56948';
export const COURT = '3f8fc265-8ffe-4ba3-ad3d-6b163ef56948';
export const EARNINGS_CALENDAR = '4f8fc265-8ffe-4ba3-ad3d-6b163ef56948';
export const INSIDER = '5f8fc265-8ffe-4ba3-ad3d-6b163ef56948';
export const SPLITS = '6f8fc265-8ffe-4ba3-ad3d-6b163ef56949';
export const EARNINGS = '7f8fc265-8ffe-4ba3-ad3d-6b163ef56948';
export const INDUSTRIAL = '8f8fc265-8ffe-4ba3-ad3d-6b163ef56948';
export const CRYPTO = '9f8fc265-8ffe-4ba3-ad3d-6b163ef56948';
export const ISSUE_REGISTRATION = '1d8fc265-8ffe-4ba3-ad3d-6b163ef56948';
export const REDDIT = '1d8fc265-8ffe-4ba3-ad3d-6b163ef56949';

const iconsMap = {
  [MARKET_OVERVIEW_ALL]: IconPlanet,
  [MARKET_OVERVIEW_IGC]: IconPlanet,
  [MARKET_OVERVIEW_HYC]: IconPlanet,
  [MARKET_OVERVIEW_CONSUMER]: IconPlanet,
  [LOAN_LEVEL_DATA]: IconLayersGroup,
  [BWICS]: IconBondManager,
  [CREDIT_FLOW_ARTICLES]: IconDescription,
  [TRACE]: IconTrace,
  [NEW_ISSUER_PIPELINE]: IconCard,
  [OPEN_MARKET]: IconAccount,
  [PORTFOLIO_MONITOR]: IconFolderOpen,
  [RESEARCH]: IconResearchDocument,
  [NEW_ISSUE_STATS]: IconTable,
  [BOND_SCREENER]: IconFilters,
  [CORPORATE_BOND_SCREENER]: IconFilters,
  [LEAGUE_TABLE]: IconEnumeratedList,
  [INVESTOR_SET]: IconInvestorSet,
  [CAPEDGE_HOME]: IconCapedge,
  [CALENDAR]: IconCalendar,
  [COURT]: IconBank,
  [EARNINGS_CALENDAR]: IconEarningsCalendar,
  [INSIDER]: IconUserAnalytics,
  [SPLITS]: IconMappings,
  [EARNINGS]: IconDocument,
  [INDUSTRIAL]: IconAccount,
  [CRYPTO]: IconCrypto,
  [ISSUE_REGISTRATION]: IconUnarchive,
  [REDDIT]: IconReddit,
};

class Tool {
  static getToolIconByID(toolId: string) {
    return iconsMap[toolId] || IconFilters;
  }

  static getToolName(options) {
    const toolName = (options.id === MARKET_OVERVIEW_ALL && !options.isABSProduct)
      ? 'Market Overview'
      : options.name;
    return `${ toolName }${ options.additionalName ? ` (${ options.additionalName })` : '' }`;
  }

  static getToolLink(options) {
    if (options.isExternal) {
      // For now we have only one external link, later we should change to switch here @amorgunov
      if (options.id === INVESTOR_SET) {
        return `${ config.protocol }//${ config.tenant.tenantInvestorSet.hostname }/${ options.route }`;
      }
    }
    const toolLink = {
      pathname: `/${ options.route }`,
      search: '',
    };
    if (!options.skipProductRegionAppend) {
      toolLink.search = `products=${ options.activeProductAbbreviation }&regions=${ options.activeRegionAbbreviation }`;
    }
    return toolLink;
  }
}

export default Tool;
