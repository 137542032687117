import { inject, injectable } from 'inversify';
import Request from '@/Framework/api/Rpc/Request';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import RpcDispatcher from '@/finsight/application/DI/Rpc/finsight-web/HttpDispatcher';

@injectable()
class BreadcrumbsRepository {
  constructor(@inject(RpcDispatcher) protected readonly rpc: typeof RpcDispatcher) {}

  getBreadcrumbs = async (viewId: number) => {
    const request = new Request('finsight_web.frontend.finsight.get_breadcrumbs', {
      issuerViewId: viewId,
    });
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };
}

export default BreadcrumbsRepository;
