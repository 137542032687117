import URL from '@finsight/router';

export const redirectMap = {
  [URL.OLD_ROUTES.SOLUTIONS]: URL.SOLUTIONS,
  [URL.OLD_ROUTES.BOOK_DEMO_ALLOCATE]: URL.BOOK_DEMO_ALLOCATE,
  [URL.OLD_ROUTES.POLICIES_COMPLIANCE]: URL.POLICIES_COMPLIANCE,
  [URL.OLD_ROUTES.POLICIES_PRIVACY]: URL.POLICIES_PRIVACY,
  [URL.OLD_ROUTES.POLICIES_SECURITY]: URL.POLICIES_SECURITY,
  [URL.OLD_ROUTES.POLICIES_TERMS]: URL.POLICIES_TERMS,
  [URL.OLD_ROUTES.PRODUCT_US_ABS_PIPELINE]: URL.PRODUCT_US_ABS_PIPELINE,
  [URL.OLD_ROUTES.PRODUCT_US_ABS_EE]: URL.PRODUCT_US_ABS_EE,
  [URL.OLD_ROUTES.MARKET_OVERVIEW_ALL]: URL.MARKET_OVERVIEW_ALL,
  [URL.OLD_ROUTES.MARKET_OVERVIEW_CONSUMER]: URL.MARKET_OVERVIEW_CONSUMER,
  [URL.OLD_ROUTES.ABS_BONDSCREENER]: URL.ABS_BONDSCREENER,
  [URL.OLD_ROUTES.CONTACT]: URL.CONTACT,
  [URL.OLD_ROUTES.FEATURES]: URL.FEATURES,
};
