import { inject, injectable } from 'inversify';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import RpcDispatcher from '@/finsight/application/DI/Rpc/finsight-web/HttpDispatcher';
import Request from '@/Framework/api/Rpc/Request';
import BaseFinsightRepository from '@/finsight/infrastructure/repository/BaseFinsightRepository';
import IDictionaries from '@/finsight/domain/vo/dictionaries/IDictionaries';

@injectable()
class HeaderRepository extends BaseFinsightRepository {
  constructor(
    @inject(RpcDispatcher) protected readonly rpc: typeof RpcDispatcher,
  ) {
    super(rpc);
  }

  getDictionaries = async (): Promise<IDictionaries> => {
    const request = new Request('finsight_web.frontend.finsight.get_dictionaries', {});
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

search = async (searchString: string) => this.abortableCall(
    new Request('finsight_web.frontend.finsight.get_search', { searchString }),
  );

searchDeals = async (searchString: string) => this.abortableCall(
    new Request('finsight_web.market.search.deals', { searchString }),
  );
}

export default HeaderRepository;
