import * as actionTypes from './actionTypes';
import ModalFormRepository from '@/finsight/infrastructure/repository/ModalFormRepository';
import createAction from '@/Framework/State/Redux/createAction';
import createAsyncRequest from '@/Framework/State/Redux/createAsyncRequest';

export const setIsMissingSomethingModalVisible = (payload: boolean) => createAction(
  actionTypes.SET_IS_MISSING_SOMETHING_MODAL_VISIBLE,
  payload,
);

export const submitMissingSomethingModalForm = (file, values) => (dispatch, getState) => {
  const modalFormRepository = getState().container.get(ModalFormRepository);

  return dispatch(createAsyncRequest(
    {
      method: modalFormRepository.sendMissingSomethingModalForm,
      payload: {
        feedbackText: values.comments,
        email: values.email || '',
        referrer: window.location.href,
        token: values.recaptcha,
        file,
      },
      types: [
        actionTypes.SEND_MISSING_SOMETHING_MODAL,
        actionTypes.SEND_MISSING_SOMETHING_MODAL_SUCCESS,
        actionTypes.SEND_MISSING_SOMETHING_MODAL_FAILURE,
      ],
    },
  ));
};

export const resetFinsightModalForm = () => createAction(actionTypes.RESET_FINSIGHT_MODAL_FORM);
