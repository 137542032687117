import { getMarketOverviewBreadcrumbsRoute, getParentBreadcrumbsRoute } from '@/finsight/ui/common/helpers';

const getIssuerBreadcrumbs = (filters, parent, issuer) => ([
  getMarketOverviewBreadcrumbsRoute(filters, true),
  getParentBreadcrumbsRoute(parent),
  {
    title: issuer.name,
    isActive: true,
  },
]);

export default getIssuerBreadcrumbs;
