import React, { useMemo } from 'react';
import cn from 'classnames';
import ResultsItem from './ResultsItem';
import { useDictionariesContext } from '@/finsight/application/Dictionaries/DictionariesContext';
import { IRegion } from '@/finsight/domain/vo/Region';

import styles from './resultsColumn.scss';
import headerStyles from '@/finsight/ui/common/components/header/Header/header.scss';

interface IProps {
  product: any,
  searchResult: any,
  onSearchResultItemClick: () => void,
  activeItemName: string,
}

const ResultsColumn = ({ activeItemName = '', searchResult, product, onSearchResultItemClick }: IProps) => {
  const { regionsList } = useDictionariesContext();
  const reversedRegions = useMemo(() => [...regionsList].reverse(), [regionsList]);
  const renderItem = (region: IRegion) => {
    const key = `${ product.abbreviation.toLowerCase() }_${ region.abbreviation.toLowerCase() }`;
    const data = searchResult.deals && searchResult.deals[key] ? searchResult.deals[key].collection : [];

    return (
      <ResultsItem
        productAbbreviation={ product.abbreviation }
        regionAbbreviation={ region.abbreviation }
        activeItemName={ activeItemName }
        key={ region.id }
        title={ region.abbreviation }
        data={ data }
        isBeta={ !product.beta && region.beta }
        onClick={ onSearchResultItemClick }
      />
    );
  };
  return (
    <div className={ cn(styles.column, {
      [styles.noResultsForFirstColumn]: !searchResult.hasFirstColumnData,
    }) }
    >
      <div className={ styles.columnTitle }>
        { product.name } ({ product.abbreviation })
        { product.beta && (
          <span className={ headerStyles.beta }>(Beta)</span>
        ) }
      </div>
      { reversedRegions.map(renderItem) }
    </div>
  );
};

export default ResultsColumn;
