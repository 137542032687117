import React, { createContext, useState, useContext, useEffect } from 'react';

export function useSetPageAsStatic() {
  const {
    isStaticPage,
    setIsStaticPage
  } = useStaticPagesContext();

  useEffect(() => {
    setIsStaticPage(true);
    return () => setIsStaticPage(false);
  }, []);

  return {
    isStaticPage,
    setIsStaticPage
  };
}

const useStaticPages = () => {
  const [isStaticPage, setIsStaticPage] = useState<boolean>(false);

  return {
    isStaticPage,
    setIsStaticPage
  };
};

type StaticPagesContextType = ReturnType<typeof useStaticPages>;

export const StaticPagesContext = createContext<StaticPagesContextType>(null);

export function useStaticPagesContext() {
  const context = useContext(StaticPagesContext);
  if (!context) {
    throw new Error('useStaticPagesContext must be used within the StaticPagesContext');
  }
  return context;
}

interface IProps {
  children: React.ReactNode
}

const StaticPagesContextProvider = ({ children }: IProps) => (
  <StaticPagesContext.Provider value={ useStaticPages() }>
    { children }
  </StaticPagesContext.Provider>
);

export default StaticPagesContextProvider;
