import finsightWebUrl from '@/finsight/infrastructure/finsightWebUrl';
import performRequest from '@/Framework/api/Http/performRequest';

class RouteServiceRepository {
  static getApiCookies = async () => {
    const response = await performRequest(finsightWebUrl.getApiUrl('/cookies'));
    return response.json();
  };
}

export default RouteServiceRepository;
