import { matchPath } from 'react-router-dom';
import URL from '@finsight/router';
import { toSlug } from '@/Framework/url/helpers/toSlug';
import { formatDealName, IDealNameParams } from '@/finsight/application/actions/deals/helpers';
import { redirectMap } from './redirectMap';
import Region, { RegionAbbreviations } from '@/finsight/domain/Region';
import Product, { ProductAbbreviations } from '@/finsight/domain/Product';
import ICompany from '@/finsight/domain/vo/ICompany';

export interface IGetDealRouteParams extends IDealNameParams {
  viewId: string | number,
}

export const getDealRoute = (deal: IGetDealRouteParams): string => {
  const { viewId } = deal;
  return `${ URL.DEAL }-${ viewId.toString() }-${ toSlug(formatDealName(deal)) }`;
};

export const getParentRoute = (
    { viewId, name },
): string => `${ URL.SPONSOR }-${ viewId }-${ toSlug(name) }`;

export const getIssuerRoute = (
    { viewId, name },
): string => `${ URL.ISSUER }-${ viewId }-${ toSlug(name) }`;

export const getCatalogRoute = (
  url: string,
  company: ICompany,
) => `${ url }-${ company.viewId }-${ toSlug(company.name) }?products=${
    [
      ProductAbbreviations.ABS,
      ProductAbbreviations.HYC,
      ProductAbbreviations.IGC,
    ].join(',')
  }&regions=${
    [
      RegionAbbreviations.APAC,
      RegionAbbreviations.EMEA,
      RegionAbbreviations.LATM,
      RegionAbbreviations.USOA,
    ].join(',')
  }`;

export const getIndustryRoute = (industry: string, subindustry: string = '') => {
  let convertedIndustry = toSlug(decodeURI(industry));
  if (subindustry) {
    convertedIndustry += `-${ toSlug(decodeURI(subindustry)) }`;
  }
  return `/${ convertedIndustry }-${ URL.INDUSTRY_SUFFIX }`;
};

export const getSectorRoute = (sector: string, subsector: string = '') => {
  let convertedSector = toSlug(decodeURI(sector));
  if (subsector) {
    convertedSector += `-${ toSlug(decodeURI(subsector)) }`;
  }
  return `/${ convertedSector }-${ URL.SECTOR_SUFFIX }`;
};

export const getNewRoute = (path: string, search: string = '') => {
  if (path.indexOf(URL.OLD_ROUTES.INDUSTRY) === 0) {
    const { params: { industry, subindustry } } = matchPath(path, `${ URL.OLD_ROUTES.INDUSTRY }/:industry/:subindustry?`);
    return getIndustryRoute(industry, subindustry);
  }
  if (path.indexOf(URL.OLD_ROUTES.SECTOR) === 0) {
    const { params: { sector, subsector } } = matchPath(path, `${ URL.OLD_ROUTES.SECTOR }/:sector/:subsector?`);
    return getSectorRoute(sector, subsector);
  }
  if (path === URL.OLD_ROUTES.MARKET_OVERVIEW_ALL) {
    const searchParams = new URLSearchParams(search);
    const products = searchParams.get('products');
    let regions = searchParams.get('regions');

    if (!products) {
      return `${ URL.MARKET_OVERVIEW_ALL }?products=${ Product.getDefault() }&regions=${ Region.getDefault() }`;
    }
    if (!regions) {
      searchParams.set('regions', Region.getDefault());
    }

    if (products.indexOf(ProductAbbreviations.ABS) > -1) {
      return `${ URL.MARKET_OVERVIEW_ALL }?${ searchParams.toString() }`;
    }
    if (products.indexOf(ProductAbbreviations.IGC) > -1) {
      return `${ URL.MARKET_OVERVIEW_IGC }?${ searchParams.toString() }`;
    }
    if (products.indexOf(ProductAbbreviations.HYC) > -1) {
      return `${ URL.MARKET_OVERVIEW_HYC }?${ searchParams.toString() }`;
    }
  }

  return redirectMap[path];
};
