import Dispatcher from '@dealroadshow/json-rpc-dispatcher';
import createAction from '@/Framework/State/Redux/createAction';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';

const defaultCallbacks = {
  responseHandler: null,
  onSuccess: null,
  onError: null,
};

/**
 *
 * @param {Object} config
 * @param {Object} config.payload
 * @param {Function} config.method
 * @param {Array} config.types // 0 - Request, 1 - Success, 2 - Error
 * @param {Object} [config.callbacks]
 *
 * @returns {Function}
 *
 * */
export const createAsyncRequest = (
  {
    payload = {},
    method,
    types: [REQUEST_TYPE, SUCCESS_TYPE, ERROR_TYPE],
    callbacks = defaultCallbacks,
  },
) => async (dispatch, getState) => {
  dispatch(createAction(REQUEST_TYPE));

  try {
    const response = await method(payload, dispatch, getState);

    if (callbacks.onSuccess) {
      callbacks.onSuccess({ response, payload, dispatch, getState });
    }

    dispatch(createAction(SUCCESS_TYPE, callbacks.responseHandler ? callbacks.responseHandler(response) : response));
  } catch (error) {
    if (Dispatcher.isAbortError(error)) {
      return;
    }

    if (callbacks.onError) {
      callbacks.onError({ error, payload, dispatch, getState });
    } else {
      AlertManager.error(getErrorMessage(error));
    }

    dispatch(createAction(ERROR_TYPE, error));
  }
};

export default createAsyncRequest;
